import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { API_BASE_URL, INITIAL_VALUES, } from '../../const';
import axios from "axios";
import CustomUpdateForm from '../../components/CustomUpdateForm';
import CustomModal from '../../components/CustomModal';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {  RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth as firebaseAuth } from '../../firebase';
import { loadingContext } from '../../App';

const UpdateUser = () => {

  const {setLoading} = useContext(loadingContext);

  const [show,setShow] = useState(false);
  const [recap,setRecap] = useState(null);
  const [form,setForm] = useState(INITIAL_VALUES);
  const [auth,setAuth] = useState(false);
  const [result,setResult] = useState(null);
  const [disabled,setDisabled] = useState(true);

  let { userId } = useParams();


  useEffect(() => {

    const fetchData = async()=>{
      try {
        setLoading(true);
        const result = await axios.get(`${API_BASE_URL}/users/${userId}`,{
          "headers": {
            "content-type": "application/json",
            }
        });
        const formObj = result.data;
          formObj.contact_number = formObj.contact_person.length;
          formObj.contact_person.push({first_name:'',
          last_name:'',
          phone:'',
          relation:'',
        });
        setForm({...formObj});
      } catch (error) {
        console.log(error);
      } finally{
        setLoading(false);
      }
    }
    //fetch data
    fetchData();
  },[userId,setLoading])

  const  removeLeadingZeros = (phoneNumber)=> {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);
    const formattedNumber = parsedNumber?.formatInternational();
    return formattedNumber;
  }
  
  const verifyRecaptcha = ()=>{
    console.log(recap);
    if(recap===null){
      let recaptcha = new RecaptchaVerifier('recaptcha-container', {
        'size': 'normal',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          setRecap(recaptcha);
          //setShowRecap(false);
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
        }
      },firebaseAuth);
      sendOTP(recaptcha);
    }
    else{
      sendOTP(recap);
    }
  }


  const sendOTP = async (recaptcha)=>{
    const formattedNumber = removeLeadingZeros(form.phone);
    try {
      const result = await signInWithPhoneNumber(firebaseAuth,formattedNumber,recaptcha);
      console.log(result);
      setShow(true);
      setResult(result);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <CustomModal 
        show={show} 
        setShow={setShow} 
        auth={auth} 
        setAuth={setAuth} 
        phone={removeLeadingZeros(form.phone)} 
        result={result}
        setDisabled={setDisabled}
        verifyRecaptcha={verifyRecaptcha}
        setRecap={setRecap}
      />
      <CustomUpdateForm 
        initial_values={form} 
        auth={auth} 
        setAuth={setAuth} 
        verifyRecaptcha={verifyRecaptcha}
        disabled={disabled}
        userId={userId}
        setShow={setShow}
        recap={recap}
      />
    </>
  )
}

export default UpdateUser