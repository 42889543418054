export const API_BASE_URL="https://te3q5rkf6c.execute-api.us-east-1.amazonaws.com/dev/api/v1";
//export const API_BASE_URL="http://localhost:3000/api/v1";

export const BLOOD_TYPES=['A+','A-','B+','B-','O+','O-','AB+','AB-','Not specified'];

export const GENDER_TYPES=['Male','Female','Rather not say'];

export const RELATION_TYPES=['Mother','Father','Daughter','Son','Sister','Brother','Uncle','Friend','Wife','Husband','Cousin'];

export const INITIAL_VALUES = {
  first_name: '',
  last_name: '',
  phone: '',
  dob: '',
  gender: '',
  blood_type: '',
  contact_person:[{
    first_name: '',
    last_name: '',
    phone: '',
    relation: '',
  }],
  contact_number:0,
};