import * as yup from "yup";
import "yup-phone";
import { BLOOD_TYPES,GENDER_TYPES } from '../const';

export const contactPersonSchema = yup.object().shape({
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    phone: yup.string().required("Phone is required").phone(null,null,"Phone format is invalid"),
    relation: yup.string().required("Relation is required"),
});

export const userSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  phone: yup.string().required("Phone is required").phone(null,null,"Phone format is invalid"),
  dob: yup.date().required("Date Of Birth is required"),
  gender: yup.string().required("Gender is required").oneOf(GENDER_TYPES,'Invalid input value.'),
  blood_type: yup.string().required("Blood Type is required").oneOf(BLOOD_TYPES,'Invalid input value.'),
  contact_number: yup.number(),
  contact_person : yup.array().min(2,"You must have at least one contact person."),
});