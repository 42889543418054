import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const config = {
  apiKey: "AIzaSyApAtnRKTRpD8hlTJ15_0u34BjMUvB9JrM",
  authDomain: "pmi-mern.firebaseapp.com",
  projectId: "pmi-mern",
  storageBucket: "pmi-mern.appspot.com",
  messagingSenderId: "296443590609",
  appId: "1:296443590609:web:482483a60b0bd0e05c92f0",
  measurementId: "G-WQKNQK03X4"
};

//firebase.initializeApp(config);

const app = initializeApp(config);
const auth = getAuth(app);

export {auth};
