import React, { useState } from 'react';
import CustomForm from '../../components/CustomForm';
import QrCode from '../../components/QrCode';

const CreateUser = () => {

  const [isRegistered,setIsRegistered] = useState({
    registered: false,
    dataUrl: '',
    fName:'',
  });

  return (
    isRegistered.registered ?
      <QrCode isRegistered={isRegistered}  setIsRegistered={setIsRegistered}/>
        :
      <CustomForm setIsRegistered={setIsRegistered}/>
  )
}

export default CreateUser