import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CustomInput from "./CustomInput";

const CustomInputGroup = (props) => {
  const {
    id1,
    id2,
    value1,
    value2,
    error1,
    error2,
    isInvalid1,
    isInvalid2,
    onChange,
    disabled,
  } = props;

  return (
    <>
      <CustomInput
        id={id1}
        value={value1}
        type="text"
        label="First name"
        placeholder="First name"
        onChange={onChange}
        error={error1}
        isInvalid={isInvalid1}
        disabled={disabled}
      />
      <CustomInput
        id={id2}
        value={value2}
        type="phone"
        label="Last name"
        placeholder="Last name"
        onChange={onChange}
        error={error2}
        isInvalid={isInvalid2}
        disabled={disabled}
      />
    </>
  );
};

export default CustomInputGroup;
