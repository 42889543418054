import React from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const CustomPhoneInput = (props) => {

  const {
    value,
    setValue,
    error,
    disabled,
    label,
    id,
    placeholder,
    isInvalid,
    defaultCountry,
  } = props;

  const onValueChange = phoneNumber => {
    setValue({
      target: { name: id, value: phoneNumber },
    });
};

  return (
    <FloatingLabel
      controlId={id}
      label={label}
      className="mb-3"
    >
      <PhoneInput
        defaultCountry={defaultCountry}
        id={id}
        name={id}
        placeholder={placeholder}
        value={value}
        onChange={onValueChange}
        disabled={disabled}
        className={`form-control ${isInvalid?"is-invalid":""} rounded-pill`}
        style={{overflowX:"hidden"}}
      />
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
    </FloatingLabel>
  )
}

export default CustomPhoneInput