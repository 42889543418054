import React, { useRef } from 'react';
import jsPDF from 'jspdf';
import StarFill  from 'react-bootstrap-icons/dist/icons/star-fill';


const QrCode = (props) => {

  const {
    isRegistered,
    setIsRegistered
  } = props;

  const img = useRef();

  const generatePDF = ()=>{
    try {
      var doc = new jsPDF('portrait','px','a4','false');
      doc.setFontSize(50);
      doc.text("Scan",150,50);
      doc.setFontSize(20);
      doc.text(`First Name : ${isRegistered.fName}`,150,95);
      doc.html( img.current, {
        callback: function (pdf) {
          pdf.save("qrcode.pdf");
        },
        x:125,
        y:100,
     });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="col col-md-6 mx-auto py-5 px-sm-5 px-3" id="qrcode" >
      <h2 className="page-section-heading text-center text-secondary">Scan QR Code</h2>
      <img
        ref={img}
        className="rounded mx-auto d-block mb-5"
        src={isRegistered.dataUrl} alt="QR-code" />
      <button 
        className="btn btn-lg w-100 bg-primary rounded-pill text-white fs-3 fw-bold mb-3"
        onClick={generatePDF}
      >
        Download PDF
      </button>
      <button 
        className="btn btn-lg w-100 bg-primary rounded-pill text-white fs-3 fw-bold"
        onClick={()=>setIsRegistered({registered:false,dataUrl:'',fName:'',})}
      >
        Create new user
      </button>
    </div>
  )
}

export default QrCode