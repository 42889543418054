import React, { useRef } from "react";
import StarFill from "react-bootstrap-icons/dist/icons/star-fill";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CustomInput from "./CustomInput";
import contactSchema from "../validation/ContactValidation";
import { Formik } from "formik";
import emailjs from "@emailjs/browser";

const CustomContactForm = (props) => {
  const form = useRef();

  const initial_values = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  const submitButton = (values, action) => {
    emailjs
      .sendForm(
        "service_zce6oq2",
        "template_xs0rc8q",
        form.current,
        "pREPpjkuU35zUe2vI"
      )
      .then(
        (result) => {
          console.log(result.text);
          action.resetForm();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div
      className="d-flex align-items-start justify-content-start flex-column p-3 bg-contact"
      id="contact"
    >
      <h6 className="page-section-heading text-uppercase text-light">
        Contact Us
      </h6>
      <h2 className="page-section-heading text-uppercase text-light">
        HAVE A QUESTION
      </h2>
      <Formik
        validationSchema={contactSchema}
        onSubmit={submitButton}
        initialValues={initial_values}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form ref={form} noValidate onSubmit={handleSubmit} className="w-100 d-flex justify-content-start flex-column">
            <CustomInput
              id="name"
              type="text"
              label="Full name"
              placeholder="Full name"
              value={values.name}
              onChange={handleChange}
              error={errors.name}
              isInvalid={!!errors.name}
            />
            <CustomInput
              id="email"
              type="email"
              label="Email address"
              placeholder="Email address"
              value={values.email}
              onChange={handleChange}
              error={errors.email}
              isInvalid={!!errors.email}
            />
            <CustomInput
              id="phone"
              type="text"
              label="Phone number"
              placeholder="Phone number"
              value={values.phone}
              onChange={handleChange}
              error={errors.phone}
              isInvalid={!!errors.phone}
            />
            <CustomInput
              id="message"
              type="textarea"
              label="Message"
              placeholder="Message"
              value={values.message}
              onChange={handleChange}
              error={errors.message}
              isInvalid={!!errors.message}
            />
            <Button
              type="submit"
              className="fs-5 col-md-6 rounded-pill bg-primary border-0"
              size="sm"
            >
              SUBMIT
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CustomContactForm;
