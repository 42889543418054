import React from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';

const CustomInput = (props) => {

  const {
    id,
    label,
    type,
    value,
    error,
    placeholder,
    onChange,
    isInvalid,
    disabled,
  } = props;

  return (
    <FloatingLabel
      controlId={id}
      label={label}
      className="mb-3"
    >
      <Form.Control
        as={type==='textarea' ? 'textarea' : 'input'}
        type={type}
        name={id} 
        value={value} 
        placeholder={placeholder} 
        onChange={onChange}
        isInvalid={isInvalid}
        disabled={disabled}
        className={type==='textarea' ?"rounded-5":"rounded-pill"}
        size="sm"
        style={type==='textarea' ?{ height: '200px' }:{}}
       />
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
    </FloatingLabel>
  )
}

export default CustomInput