import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import header from "../assets/img/header.png";

const CustomHeader = () => {
  return (
    <div className="header-trick">
      <Container>
        <Row className="g-0">
          <Col
            xs={4}
            className="d-flex flex-column align-items-start justify-content-center gap-2"
          >
            <h1 className="mb-0 text-white text-start">EMPOWERING SAFETY</h1>
            <p className="font-weight-light mb-0 text-white text-start">
              GENERATE QR CODES FOR EMERGENCY PREPAREDNESS
            </p>
            <Button variant="light" size="sm" className="bg-primary text-light rounded-pill border-0" href="#qrcode">LET'S GENERATE IT</Button>
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            <img
              src={header}
              alt="avatar"
              style={{
                width: "40vw",
                aspectRatio: "1:1",
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CustomHeader;
