import React,{useRef, useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const CustomModal = (props) => {

  const {
    show,
    phone,
    setShow,
    setAuth,
    result,
    setDisabled,
    verifyRecaptcha,
    setRecap,
  }=props;

  const otpRef = useRef(null);

  const [otp,setOtp] = useState(Array(6).fill(''));
  const [curr,setCurr] = useState(0);
  const [showError,setShowError] = useState(false);

  const handleChange = (e,index)=>{
    var otpArray=[...otp];
    var currOtp = e.target.value.substring(e.target.value.length-1);
    otpArray[index] = currOtp;
    setOtp(otpArray);
    if(e.target.value!==''){
      if(curr===otp.length-1) return;
      setCurr(curr+1);
    }
  }

  useEffect(() => {
    otpRef.current?.focus();
  }, [curr])

  const handleValidate = ()=>{
    const OTP = otp.join('');
    result.confirm(OTP).then((e)=>{
      setAuth(true);
      setShow(false);
      setDisabled(false);
      console.log(e);
    }).catch((err)=>{
      setShowError(true);
      console.log(err);
    })
  }

  const handleResend = ()=>{
    setRecap(null);
    verifyRecaptcha();
    setShow(false);
  }

  const handleKeyDown = (e,index) =>{
    if(e.key === "Backspace" && e.target.value==='' && index!==0){
      setCurr(curr-1);
    }
  }

  const handleClose = () => setShow(false);

  return (
      <Modal 
       show={show} 
       onHide={handleClose}
       backdrop="static"
       keyboard={false}
       aria-labelledby="contained-modal-title-vcenter"
       centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Mobile phone verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter the OTP sent to phone number {phone}</p>
          <Form>
              <div className='col-12 d-flex flex-row justify-content-center align-items-center'>
                {
                  otp.map((otpNum,index)=>(
                      <Form.Control
                        className='mx-1'
                        key={index}
                        ref={index === curr? otpRef : null}
                        type="text"
                        name="otp"
                        onChange={(e)=>handleChange(e,index)}
                        value={otp[index]}
                        disabled={index !== curr}
                        onKeyDown={(e)=>handleKeyDown(e,index)}
                      />
                  ))
                }
              </div>
            </Form>
            {showError && <p className="text-danger mt-3">Code you entered didn't match</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleValidate}>
            Validate
          </Button>
          <Button variant="secondary" onClick={handleResend}>
            Resend Code
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default CustomModal