import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import StarFill from "react-bootstrap-icons/dist/icons/star-fill";
import axios from "axios";
import { Formik, validateYupSchema, yupToFormErrors } from "formik";
import { userSchema, contactPersonSchema } from "../validation/UserValidation";
import { API_BASE_URL, BLOOD_TYPES, GENDER_TYPES } from "../const";
import CustomTable from "./CustomTable";
import CustomSelect from "./CustomSelect";
import CustomInput from "./CustomInput";
import CustomInputGroup from "./CustomInputGroup";
import CustomContactPerson from "./CustomContactPerson";
import { Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import { loadingContext } from "../App";
import CustomPhoneInput from "./CustomPhoneInput";
import Person from "react-bootstrap-icons/dist/icons/person-fill";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const CustomUpdateForm = (props) => {
  const {
    initial_values,
    auth,
    verifyRecaptcha,
    disabled,
    userId,
    setShow,
    recap,
  } = props;

  const { setLoading } = useContext(loadingContext);

  const [contactErr, setContactErr] = useState();

  const [editIndex, setEditIndex] = useState(-1);

  const checkValidity = async (values) => {
    const val = values;
    try {
      await validateYupSchema(val, contactPersonSchema, true, val);
      setContactErr();
      return true;
    } catch (err) {
      const res = yupToFormErrors(err);
      setContactErr(res);
      return false;
    }
  };

  const addContact = async (values, handleChange) => {
    const isValid = await checkValidity(
      values.contact_person[values.contact_number]
    );
    if (!isValid) return;

    const newItems = [
      ...values.contact_person,
      {
        first_name: "",
        last_name: "",
        phone: "",
        relation: "",
      },
    ];

    handleChange({
      target: { name: "contact_person", value: newItems },
    });
    handleChange({
      target: { name: "contact_number", value: values.contact_number + 1 },
    });
  };

  const editContact = async (values, handleChange) => {
    const isValid = await checkValidity(
      values.contact_person[values.contact_number]
    );
    if (!isValid) return;

    const newItems = values.contact_person;
    newItems[editIndex] = values.contact_person[values.contact_number];

    newItems[values.contact_number] = {
      first_name: "",
      last_name: "",
      phone: "",
      relation: "",
    };

    handleChange({
      target: { name: "contact_person", value: newItems },
    });

    setEditIndex(-1);
  };

  const submitButton = async (values, action) => {
    if (auth) {
      const {
        first_name,
        last_name,
        phone,
        dob,
        gender,
        blood_type,
        contact_person,
      } = values;
      const contactList = contact_person.slice(0, -1);

      const params = {
        first_name,
        last_name,
        dob,
        gender,
        blood_type,
        phone,
        contact_person: [...contactList],
      };
      console.table(params);
      try {
        setLoading(true);
        const result = await axios.post(
          `${API_BASE_URL}/users/${userId}`,
          [params],
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );
        console.log(result);
        toast.success("Successfully updated!");
      } catch (error) {
        console.log(error);
        toast.error("Failed to update");
      } finally {
        setLoading(false);
      }
    } else {
      if (recap === null) {
        verifyRecaptcha();
      } else {
        setShow(true);
      }
    }
  };

  return (
    <div className="col col-md-10 py-5 ms-auto mt-5" id="qrcode">
      {!auth && (
        <Alert className="fs-5 w-100" variant="danger">
          Please request update to enable modification
        </Alert>
      )}
      <Formik
        validationSchema={userSchema}
        onSubmit={submitButton}
        enableReinitialize
        initialValues={initial_values}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => {
          return (
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                console.table(errors);
                handleSubmit();
              }}
              className="bg-third mb-5 rounded-start"
            >
              <Row xs={1} md={1} lg={2}>
              <Col className="personal-info p-5">
                <h5 className="text-start">
                  <Person />
                  YOUR PERSONAL INFO
                </h5>
                <CustomInputGroup
                  id1="first_name"
                  id2="last_name"
                  value1={values.first_name}
                  value2={values.last_name}
                  error1={errors.first_name}
                  error2={errors.last_name}
                  isInvalid1={!!errors.first_name}
                  isInvalid2={!!errors.last_name}
                  onChange={handleChange}
                  disabled={disabled}
                />
                <CustomPhoneInput
                  value={values.phone}
                  setValue={handleChange}
                  setFieldValue={setFieldValue}
                  id="phone"
                  label="Phone Number"
                  placeholder="Enter phone number"
                  error={errors.phone}
                  isInvalid={!!errors.phone}
                  disabled={disabled}
                />
                <CustomInput
                  id="dob"
                  value={values.dob}
                  type="date"
                  label="Date of birth"
                  placeholder="Date of birth"
                  onChange={handleChange}
                  error={errors.dob}
                  isInvalid={!!errors.dob}
                  disabled={disabled}
                />
                <CustomSelect
                  id="gender"
                  label="Gender"
                  placeholder="Choose Gender"
                  value={values.gender}
                  selectList={GENDER_TYPES}
                  onChange={handleChange}
                  error={errors.gender}
                  isInvalid={!!errors.gender}
                  disabled={disabled}
                />
                <CustomSelect
                  id="blood_type"
                  label="Blood type"
                  placeholder="Choose blood type"
                  value={values.blood_type}
                  selectList={BLOOD_TYPES}
                  onChange={handleChange}
                  error={errors.blood_type}
                  isInvalid={!!errors.blood_type}
                  disabled={disabled}
                />
              </Col>
              <Col className="contact-info p-5 rounded-start bg-fourth d-flex flex-row flex-wrap">
                <div>
                  <h5 className="text-start">
                    <Person />
                    CONTACT PERSON
                  </h5>
                  {!values.contact_number && (
                    <p className="my-1 text-danger">{errors.contact_person}</p>
                  )}

                  <CustomContactPerson
                    index={values.contact_number}
                    values={values}
                    contactErr={contactErr}
                    checkValidity={checkValidity}
                    handleChange={handleChange}
                    disabled={disabled}
                  />
                  <Button
                    type="button"
                    onClick={() => {
                      editIndex === -1
                        ? addContact(values, handleChange, errors)
                        : editContact(values, handleChange, errors);
                    }}
                    disabled={disabled}
                    className="mb-3 col-12 bg-yellow rounded-pill border-0 py-2 px-3 text-start"
                    size="lg"
                  >
                    {editIndex === -1
                      ? "Add Contact Person"
                      : "Update Contact Person"}
                  </Button>
                  {/*
        <CustomTable
          contacts={values.contact_person}
          handleValues={handleChange}
          count={values.contact_number}
          setEditIndex={setEditIndex}
          checkValidity={checkValidity}
          disabled={disabled}
        />
        */}
                  <div
                    className="d-flex flex-row justify-content-center align-items-center mt-5"
                    id="recaptcha-container"
                  ></div>
                  <Button
                    type="submit"
                    className="bg-primary rounded-pill border-0 py-2 px-3"
                    size="lg"
                  >
                    {auth ? "Update" : "Request Update"}
                  </Button>
                </div>
                <div className="contacts-container text-light">
                  {values.contact_person.slice(0, -1).map((person, index) => {
                    const { first_name, last_name, phone, relation } = person;
                    return (
                      <div className="contact-box" key={index}>
                        <div className="d-flex flex-row justify-content-between align-items-center p-2">
                          <Person />
                          <span>{index}</span>
                        </div>
                        <div className="contacts-info d-flex flex-column p-2">
                          <span className="text-start">{`${first_name} ${last_name}`}</span>
                          <span className="text-start">{phone}</span>
                          <span className="text-start">{relation}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CustomUpdateForm;
