import * as yup from "yup";
import "yup-phone";

const schema = yup.object().shape({
  name: yup.string().required("Full Name is required"),
  email: yup.string().email().required("Email is required"),
  phone: yup.string().required("Phone is required").phone(null,null,"Phone format is invalid"),
  message: yup.string().required("Message is required").max(300),
});

export default schema;