import React, { useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { loadingContext } from '../App'

const CustomLoading = () => {

  const {loading} = useContext(loadingContext);

  return (
    <Modal 
       show={loading}
       backdrop="static"
       keyboard={false}
       aria-labelledby="contained-modal-title-vcenter"
       centered
       className='d-flex flex-row justify-content-center align-items-center modal-transparent'
    >
      <Spinner animation="border" variant="light"/>
    </Modal>
  )
}

export default CustomLoading