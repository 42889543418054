import React, { useContext } from "react";
import CustomInput from "./CustomInput";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CustomSelect from "./CustomSelect";
import CustomPhoneInput from "./CustomPhoneInput";
import { Globalcontext } from "../App";

const CustomContactPerson = (props) => {
  const {
    index,
    values,
    contactErr,
    checkValidity,
    handleChange,
    disabled,
    setFieldValue,
    defaultCountry,
  } = props;

  const relations = useContext(Globalcontext);

  return (
    <>
      <CustomInput
        id={`contact_person.${index}.first_name`}
        value={values.contact_person[index].first_name}
        type="text"
        label="First name"
        placeholder="First name"
        onChange={(e) => {
          handleChange(e);
          checkValidity({
            ...values.contact_person[index],
            first_name: e.target.value,
          });
        }}
        error={contactErr?.first_name}
        isInvalid={!!contactErr?.first_name}
        disabled={disabled}
      />
      <CustomInput
        id={`contact_person.${index}.last_name`}
        value={values.contact_person[index].last_name}
        type="text"
        label="Last name"
        placeholder="Last name"
        onChange={(e) => {
          handleChange(e);
          checkValidity({
            ...values.contact_person[index],
            last_name: e.target.value,
          });
        }}
        error={contactErr?.last_name}
        isInvalid={!!contactErr?.last_name}
        disabled={disabled}
      />
      <CustomPhoneInput
        value={values.contact_person[index].phone}
        error={contactErr?.phone}
        isInvalid={!!contactErr?.phone}
        disabled={disabled}
        setFieldValue={setFieldValue}
        label="Phone Number"
        placeholder="Enter phone number"
        id={`contact_person.${index}.phone`}
        setValue={(e) => {
          handleChange(e);
          checkValidity({
            ...values.contact_person[index],
            phone: e.target.value,
          });
        }}
        defaultCountry={defaultCountry}
      />
      <CustomSelect
        id={`contact_person.${index}.relation`}
        value={values.contact_person[index].relation}
        selectList={relations}
        onChange={(e) => {
          handleChange(e);
          checkValidity({
            ...values.contact_person[index],
            relation: e.target.value,
          });
        }}
        error={contactErr?.relation}
        isInvalid={!!contactErr?.relation}
        label="Relation type"
        placeholder="Choose relation type"
        disabled={disabled}
      />
    </>
  );
};

export default CustomContactPerson;
