import React from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';

const CustomSelect = (props) => {

  
  const {
    id,
    selectList,
    value,
    error,
    isInvalid,
    onChange,
    placeholder,
    label,
    disabled,
  } = props;

  return (
    <FloatingLabel
      controlId={id}
      label={label}
      className="mb-3"
    >
      <Form.Select
        name={id}
        value={value}
        onChange={onChange}
        error={error}
        isInvalid={isInvalid}
        disabled={disabled}
        className='rounded-pill'
      >
        <option value="" disabled>{placeholder}</option>
          {selectList?.sort((a, b)=>{
              if (a < b) {
                return -1;
              }
              if (a > b) {
                return 1;
              }
              return 0;
            }).map((option,index)=>(
            <option key={index} value={option}>{option}</option>
          ))}
      </Form.Select>
    </FloatingLabel>
        
  )
}

export default CustomSelect