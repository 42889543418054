import React from 'react';
//my components
import CreateUser from '../CreateUser/CreateUser';
import CustomFooter from '../../components/CustomFooter';
import CustomHeader from '../../components/CustomHeader';
import CustomNavbar from '../../components/CustomNavbar';
//bootstrap
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//import './index.css';
import './style.css';

const LandingPage = () => {
  return (
    <>
      <CustomNavbar/>
      <CustomHeader/>
      <CreateUser/>
      <CustomFooter/>
    </>
  )
}

export default LandingPage