import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import FaceBook from "react-bootstrap-icons/dist/icons/facebook";
import Twitter from "react-bootstrap-icons/dist/icons/twitter";
import LinkedIn from "react-bootstrap-icons/dist/icons/linkedin";
import Instagram from "react-bootstrap-icons/dist/icons/instagram";
import Google from "react-bootstrap-icons/dist/icons/google";
import Youtube from "react-bootstrap-icons/dist/icons/youtube";
import CustomContactForm from "./CustomContactForm";

const CustomFooter = () => {
  return (
    <footer className="footer text-center bg-secondary py-5" id="footer">
      <Container>
        <Row xs={1} md={1} lg={2} className="align-items-stretch justify-content-start flex-row flex-wrap">
          <Col className="py-5 d-flex align-items-start justify-content-between flex-column text-white">
            <div className="d-flex align-items-start justify-content-start flex-column">
              <h6>ABOUT US</h6>
              <h2 className="text-primary">WHY PULL MY INFO</h2>
              <p className="fs-6 text-start">
              Picture this: You're out and about, and the unexpected happens. That's where PullMyInfo comes to your rescue. Our platform empowers you to generate a personalized QR Code that holds crucial contact information. Simply print it out and attach it to your car, helmet, or any gear. In the event of an emergency, first responders or Good Samaritans can quickly scan the QR Code and access vital details, ensuring that help reaches you or your loved ones without delay. Safety meets simplicity with PullMyInfo. Be proactive, be prepared!
              </p>
              <p className="fs-6 text-start">
              </p>
            </div>
            <div className="mt-10 w-100 d-flex align-items-start justify-content-start flex-column">
              <hr className="mt-10 w-100" />
              <div className="d-flex align-items-start justify-content-start flex-row btn-container gap-1">
                <Instagram />
                <FaceBook />
                <Twitter />
                <LinkedIn />
                <Youtube />
                <Google />
              </div>
              <span className="text-start mt-2 fs-7">
                COPYRIGHT &copy; PullMyInfo 2023
              </span>
            </div>
          </Col>
          <Col className="py-5">
            <CustomContactForm />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default CustomFooter;
