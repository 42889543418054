import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { createContext, useState,useEffect } from 'react';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { API_BASE_URL, } from './const';
import axios from "axios";
import UpdateUser from './views/UpdateUser/UpdateUser';
import LandingPage from './views/LandingPage/LandingPage';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import CustomLoading from './components/CustomLoading';


export const Globalcontext = createContext([]);
export const loadingContext = createContext(false);
export const locationContext = createContext(false);

function App() {

  const [relations,setRelations] = useState([]);
  const [loading,setLoading] = useState(false);
  const [location,setLocation] = useState(false);

  const apiKey = 'e0a18dad634342fca755cc5607d02b58';
  const apiURL = `https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}&fields=country_code2`;
 
  useEffect(() => {
    //define function
    const fetchData = async()=>{
      setLoading(true);
      try {
        const result = await axios.get(`${API_BASE_URL}/relations`,{
          "headers": {
            "content-type": "application/json",
          }
        });
        console.log(result);
        setRelations(result.data); 
      } catch (error) {
        console.log(error);
      }finally{
        setLoading(false);
      }
    }
    //call defined function if relations is empty
    relations.length===0 && fetchData();
  },[relations])

  
  useEffect(() => {
    //define function
    const getUserLocationFromAPI = async() => {
      try {
        const response = await axios.get(apiURL);
        console.log(response.data);
        setLocation(response.data)
      } catch (error) {
        console.log('Something went wrong getting Geolocation from API!',error);
      }
    }
    //call defined function if relations is empty
    !location && getUserLocationFromAPI();
  },[location])

  return (
    <loadingContext.Provider  value={{loading,setLoading}}>
      <locationContext.Provider  value={location}>
      <Globalcontext.Provider  value={relations}>
        <BrowserRouter>
          <Routes>
            <Route path="/:userId" element={<UpdateUser />} />
            <Route path="*" element={<LandingPage/>}/>
          </Routes>
          <CustomLoading/>
          <ToastContainer autoClose={ 2000 } />
        </BrowserRouter>
      </Globalcontext.Provider>
      </locationContext.Provider>
    </loadingContext.Provider>
  )
}

export default App;
